import React, { SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { motion, SVGMotionProps } from 'framer-motion'
import styles from '../MobileMenu.module.scss'

import { toggleMobileMenu } from '../../../store/slices/mobileMenu'

const Path = (props: JSX.IntrinsicAttributes & SVGMotionProps<SVGPathElement> & React.RefAttributes<SVGPathElement>) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 100%)"
    strokeLinecap="round"
    {...props}
  />
)

export const Toggle = () => {
  const dispatch = useDispatch()

  function handleToggle(event: SyntheticEvent) {
    event.preventDefault()
    dispatch(toggleMobileMenu())
  }

  return (
    <>
      <button aria-label="Open Menu" onClick={handleToggle} className={styles['mobile-menu_nav__toggle']}>
        <svg width="17" height="17" viewBox="0 0 23 23">
          <Path
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' },
            }}
            strokeWidth={2.5}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            strokeWidth={2.5}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' },
            }}
            strokeWidth={2.5}
          />
        </svg>
      </button>
    </>
  )
}
