import { PropsWithChildren } from 'react'

export type RightArrowProps = {
  className: string
}

export const RightArrow:  React.FC<PropsWithChildren<RightArrowProps>> = ({
  className,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17.474" height="16.794" viewBox="0 0 36.474 32.794">
    <g transform="translate(-1286.593 -6922.193)">
      <g transform="translate(1304.819 6920.776) rotate(45)">
        <path d="M1.308,22c12.877-13.664,4.807-5.43,9.766-10.526,2.413-2.488,3.517-3.34,6-5.763,2.624-2.556,2.661-1.991,5.25-4.556a.669.669,0,0,0-.765-1.07h0c-3.1,1.819-3.149,1.8-6.14,3.821a30.85,30.85,0,0,0-5.076,4.123C4.948,12.783,3.323,14.283,0,20.912" transform="translate(0 2.987)"/>
        <path d="M18.92,1.7A1.74,1.74,0,0,0,16.757.052C11.447.9,6.022,1.287.885,2.944a1.2,1.2,0,0,0,.64,2.322c4.625-.979,9.3-1.1,13.966-1.631,0,1.753,0,3.512-.089,5.272-.083,2.4-.576,4.817-.3,7.2.134,1.126,1.849,2,2.438.64,2-4.465,1.382-10.236,1.382-15.047Z" transform="translate(5.821 0)"/>
      </g>
    </g>
  </svg>
)
