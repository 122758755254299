import { motion } from 'framer-motion'
import React, { PropsWithChildren, useRef } from 'react'
import { formattedLinksProp } from '../../../slices/ProjectDetailHeader/data'
import { ContactButton } from '../ContactButton'
import { MenuItem } from '../MenuItem'
import styles from '../MobileMenu.module.scss'

const variants = {
  open: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
}

export const MobileNav: React.FC<PropsWithChildren<formattedLinksProp>> = (props) => {
  const mobileMenuEl = useRef(null)
  const data = props.data

  return (
    <div className={styles['mobile-menu__nav']}>
      <motion.ul
        variants={variants}
        className={styles['mobile-menu__nav--menu']}
        ref={mobileMenuEl}
      >
        {data && data.map(({ label, link }, key: number) => {
          return (
            <MenuItem
              key={key}
              label={label}
              href={link.cached_url}
            />
          )
        })}
      </motion.ul>

      <ContactButton tel="1300946160" contactText="Say hello to us..." />
    </div>
  )
}
