import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Toggle } from './Toggle'
import { MobileNav } from './MobileNav'
import { MobileBackground } from './MobileBackground'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { formattedLinksProp } from '../../slices/ProjectDetailHeader/data'
import styles from './MobileMenu.module.scss'
import { rem } from '../../utils/rem'
import { Logo } from '../Logo'

export const MobileMenu = ({ data }: formattedLinksProp) => {
  const containerRef = useRef(null)
  const { windowWidth, windowHeight } = useWindowDimensions()
  const isOpen = useSelector((state) => state.mobileMenu.isOpen)
  const [sidebarChanged, setSidebarChanged] = useState<number>(0)

  const getBGWidth = () => {
    if (windowWidth < 600) {
      return '62%'
    } else if (windowWidth <= 768) {
      return '50%'
    } else if (windowWidth <= 1024) {
      return '30%'
    } else {
      return '62%'
    }
  }

  const getBGHeight = () => {
    if (windowHeight < 600) {
      return windowHeight * 1.2
    } else if (windowWidth <= 768) {
      return windowHeight * 0.625
    } else if (windowWidth <= 1024) {
      return windowHeight * 0.9
    } else {
      return windowHeight * 0.625
    }
  }

  const getBGOffsetHeight = () => {
    if (windowHeight < 768) {
      return '10%'
    } else if (windowWidth <= 768) {
      return '10%'
    } else if (windowWidth <= 1024) {
      return '-15%'
    } else {
      return '10%'
    }
  }

  const getClipPath = ()=> {
    return windowWidth <= 768 ? `circle(17px at ${rem(19.2)} 30px)` : `circle(20px at ${rem(35.2)} 41px)`
  }

  const sidebar = {
    open: () => ({
      clipPath: `circle(${getBGHeight()}px at ${getBGWidth()} ${getBGOffsetHeight()})`,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 50,
      },
      width: windowHeight <= windowWidth ? windowWidth + 100 : windowHeight,
      height: getBGHeight() * 1.2 ,
    }),
    closed: {
      width: windowWidth <= 768 ? `${rem(60)}` : `${rem(80)}`,
      height: windowWidth <= 768 ? `${rem(60)}` : `${rem(80)}`,
      clipPath: getClipPath(),
      transition: {
        delay: 1,
        duration: 1,
        type: 'spring',
        stiffness: 400,
        damping: 50,
      },
    },
  }

  const logoWrapper = {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      x: 0,
      y: 20,
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
    },
  }


  useEffect(() => {
    getClipPath()
    setSidebarChanged(()=> sidebarChanged + 1)
  }, [windowWidth])

  useEffect(() => {
    if(isOpen) {
      // @ts-ignore
      document.querySelectorAll('body')[0].className=styles.stopScroll
    } else {
      // @ts-ignore
      document.querySelectorAll('body')[0].className=' '
    }
  },[isOpen])

  return (
    <>
      <motion.div
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={windowHeight}
        ref={containerRef}
        className={isOpen ? styles['mobile-menu__open'] : styles['mobile-menu__closed']}
      >
        <motion.div
          key={sidebarChanged}
          className={styles['mobile-menu__background']}
          variants={sidebar}
        />
        <motion.div
          className={styles['mobile-menu__logo-wrapper']}
          initial={'hidden'}
          animate={isOpen ? 'visible' : 'hidden'}
          variants={logoWrapper}
        >
          <Logo
            href="/"
            className={styles['mobile-menu__inverse-logo']}
            inverse
            isOpen={isOpen}
          />
        </motion.div>
        <Toggle />
        <MobileNav data={data} isOpen={isOpen} />
        <MobileBackground />
      </motion.div>
    </>
  )
}
