import { PropsWithChildren } from 'react'
import upArrowStyles from './UpArrow.module.scss'

export type UpArrowProps = {
  className?: string
}

export const UpArrow: React.FC<PropsWithChildren<UpArrowProps>> = ({
  className,
}) => (
  <svg 
    viewBox="0 0 23.359 23.616"
    className={`${upArrowStyles.icon ?? ''} ${className ?? ''}`}
  >
    <g className={upArrowStyles.icon__container} transform="matrix(-0.695, 0.719, -0.719, -0.695, 23.615, 7.655)">
      <path className={upArrowStyles.icon__path} d="M.027,4.836a.535.535,0,0,0,.664.507,32.154,32.154,0,0,0,4.877-.889.37.37,0,0,0-.2-.714c-1.421.3-2.858.338-4.292.5,0-.539,0-1.079.028-1.62A14.917,14.917,0,0,0,1.2.409C1.16.063.633-.2.452.212-.161,1.585.027,3.358.027,4.836Z" transform="translate(0 2.294)"/>
      <path className={upArrowStyles.icon__path} d="M21.685,11.352c-.2-.572-.427-1.136-.675-1.689s-.512-1.1-.8-1.63a19.546,19.546,0,0,0-1.969-3.013,12.08,12.08,0,0,0-2.551-2.432,7.636,7.636,0,0,0-3.139-1.312c-.069-.015-.14-.021-.21-.029l-.21-.025c-.07-.01-.14-.013-.211-.017l-.211-.011-.105-.006-.106,0-.211,0c-.141,0-.282.016-.422.026A8.611,8.611,0,0,0,9.2,1.547L8.8,1.675c-.136.042-.268.1-.4.146l-.2.073-.2.083c-.132.056-.266.107-.4.166s-.262.121-.394.18-.26.128-.391.19l-.2.1c-.065.031-.13.064-.194.1l-.386.207c-.131.065-.257.141-.384.214l-.382.22c-.25.156-.5.308-.753.469l-.373.244-.187.122-.184.127L3.4,4.567c-.062.042-.125.083-.186.127l-.182.132C2.055,5.523,1.1,6.27.112,7.009h0a.07.07,0,0,1-.1-.1A20.069,20.069,0,0,1,2.407,4.082c.441-.438.909-.852,1.394-1.249.118-.1.247-.193.371-.289l.376-.285.391-.268c.131-.089.26-.18.4-.262l.406-.248c.067-.043.137-.081.207-.118l.21-.114c.278-.156.571-.288.863-.421a10.815,10.815,0,0,1,1.842-.6A9.976,9.976,0,0,1,10.808,0c.164,0,.327-.007.491.007l.245.014.123.007.122.013.244.027c.081.009.163.017.243.033l.242.042c.081.015.161.027.241.048A8.161,8.161,0,0,1,14.6.831,9.3,9.3,0,0,1,16.234,1.85a12.877,12.877,0,0,1,2.56,2.758A19.634,19.634,0,0,1,20.6,7.844a22.047,22.047,0,0,1,1.218,3.471.069.069,0,0,1-.132.039Z" transform="translate(0.369 0)"/>
    </g>
  </svg>
)
