import { useEffect, useState } from 'react'

export default function useWindowDimensions() {
  let defaultHeight
  let defaultWidth

  if (typeof window !== 'undefined') {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth
  } else {
    defaultHeight = 0
    defaultWidth = 0
  }

  const [dimensions, setDimensions] = useState({
    windowHeight: defaultHeight,
    windowWidth: defaultWidth,
  })

  useEffect(() => {
    const handler = () =>
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [dimensions])

  return dimensions
}
