import * as React from 'react'
import { motion } from 'framer-motion'
import styles from '../MobileMenu.module.scss'
import Image from 'next/image'
import background from '../../../../public/images/jungle-office.png'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: {
      delay: 0.5,
      duration: 0.5,
      y: { stiffness: 1000 },
    },
  },
}


export const MobileBackground: React.FC = () => {
  return (
    <motion.div
      variants={variants}
      className={styles['mobile-menu__footer-background']}
    >
      <Image alt="" src={background} layout={'fill'} objectFit={'cover'} />
    </motion.div>
  )
}
