import { faPhone } from '@fortawesome/pro-regular-svg-icons'
import { motion } from 'framer-motion'
import Link from 'next/link'
import * as React from 'react'
import { CircleIcon } from '../../icons/CircleIcon'
import { UpArrow } from '../../icons/UpArrow'
import styles from '../MobileMenu.module.scss'
import { PropsWithChildren } from 'react'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      delay: 1,
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export type ContactButtonProps = {
  tel?: string
  contactText?: string
}

export const ContactButton: React.FC<PropsWithChildren<ContactButtonProps>> = ({ tel, contactText }) => {
  return (
    <motion.div
      variants={variants}
      whileHover={{ scale: 1.1 }}
      className={styles['mobile-menu__nav--contact']}
    >
      <Link href={`tel:${tel}`}>
        <button aria-label="Contact" className={styles['mobile-menu__nav--contact--link']}>
          <CircleIcon iconCode={faPhone} />
        </button>
      </Link>
      <div className={styles['mobile-menu__nav--contact--text']}><span>{contactText}</span><UpArrow /></div>
    </motion.div>
  )
}
