import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
}

export const { actions, reducer } = createSlice({
  name: 'mobileMenu',
  initialState,
  reducers: {
    toggleMobileMenu: (state) => {
      state.isOpen = !state.isOpen
    },
  },
})

export const {
  toggleMobileMenu,
} = actions
