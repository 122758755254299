import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { toggleMobileMenu } from '../../../store/slices/mobileMenu'
import styles from '../MobileMenu.module.scss'

type MobileMenuItemType = {
  href: string
  label: string
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export const MenuItem: React.FC<PropsWithChildren<MobileMenuItemType>> = ({ href, label }) => {
  const linkClassName = styles['mobile-menu__nav--menu--link']
  const selectedClass = styles['mobile-menu__nav--menu--link--selected']
  const router = useRouter()
  const dispatch = useDispatch()

  const handleToggle = (event: SyntheticEvent, path: string) => {
    event.preventDefault()
    dispatch(toggleMobileMenu())
    router.push(path)
  }

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link
        href={'/'}
        passHref
      >
        <a
          href={href || '#'}
          className={`${linkClassName} ${router.asPath === href ? selectedClass : ''}`}
          tabIndex={0}
          role = "button"
          onClick={(e) => handleToggle(e, href.charAt(0) === '/' ? href :`/${href}`)}
        >
          {label}
        </a>
      </Link>
    </motion.li>
  )
}
