var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"01c8f90146311c0f56e362837b372338f5d90aad"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d1e986a2bc3b46af822301f33332e834@o99321.ingest.sentry.io/6623354',
    tracesSampleRate: 0.05,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
      }),
    ],
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  })
}
