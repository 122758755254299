import React, { PropsWithChildren } from 'react'

export type RightArrowProps = {
  className: string
  iconColor?: string
}

export const LinkListIcon:  React.FC<PropsWithChildren<RightArrowProps>> = ({
  className,
  iconColor,
}) => {
  return (
    <svg className={className}  xmlns="http://www.w3.org/2000/svg" width="18.8" height="17.763" viewBox="0 0 18.8 17.763">
      <g id="Group_1952" transform="translate(0.994 1.395)">
        <path id="Path_5139" d="M786.953,153.9l-2.9-.017a1.593,1.593,0,0,0-.732.112,1.5,1.5,0,0,0-.584.689,12.936,12.936,0,0,0-1.373,4.244,3.777,3.777,0,0,0,.144,2.034,4.1,4.1,0,0,0,2.658,1.9c1.721.573,3.663.942,5.305.17a5.328,5.328,0,0,0,2.407-2.5,11.117,11.117,0,0,0,.95-3.388" transform="translate(-781.292 -148.101)" fill="none" stroke={iconColor !== '' ? iconColor : '#a776d1'} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_5140" d="M793.032,149.181a40.051,40.051,0,0,0,12.117-11.116" transform="translate(-788.738 -138.065)" fill="none"  stroke={iconColor !== '' ? iconColor : '#a776d1'} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_5141" d="M826.594,140.565v4.4" transform="translate(-810.026 -139.651)" fill="none"  stroke={iconColor !== '' ? iconColor : '#a776d1'} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
    </svg>
  )
}
