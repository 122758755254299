import classnames from 'classnames'
import Link from 'next/link'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Underline } from '../Underline'
import { LinkListIcon } from '../icons/LinkListIcon'
import { RightArrow } from '../icons/RightArrow'
import classes from './link.module.scss'

export type ButtonVariant = 'primary' | 'primary-with-arrow' | 'secondary' | 'secondary-with-arrow' | 'list-style' | 'default' | 'menu-item'
export type CustomLinkProps = React.ComponentProps<typeof Link> & {
  variant: ButtonVariant
  href: string
  linkTarget?: boolean
  inverseColor?: boolean
  iconColor?: string
  className?: string
  alignment?: '' | 'center' | 'left' | 'right'
  isFCP?: boolean
  hover?: boolean
}

export const DefaultLink: React.FC<PropsWithChildren<{children: ReactNode}>> = ({ children })=> {
  return (
    <div className={classnames(classes.link__text)}>{children}</div>
  )
}

export const PrimaryButton: React.FC<PropsWithChildren<{hover?: boolean; children: ReactNode}>> = ({ hover, children })=> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const underlineClasses: any = [classes.link__underline]

  if (hover) underlineClasses.push(classes.hoverState)

  return (
    <div className={classnames(underlineClasses)}>
      <div className={classnames(classes.link__text)}>{children}</div>
      {classes['link__underline-svg'] &&
        <Underline className={classnames(classes['link__underline-svg'])}/>
      }
      {classes['link__underlinecover'] &&
        <Underline className={classes['link__underlinecover']}/>
      }
    </div>
  )
}

export const CustomLink: React.FC<PropsWithChildren<CustomLinkProps>> = ({
  variant,
  href,
  linkTarget,
  inverseColor,
  iconColor,
  children,
  className,
  alignment,
  isFCP,
  hover,
  ...props
}) => {
  if (href.endsWith('/')) {
    href = href.slice(0,-1)
  }

  const anchorClasses = [className, classes.link]

  if (isFCP) anchorClasses.push(classes.centredLink)

  switch (alignment) {
  case 'left':
    anchorClasses.push(classes.linkLeft)
    break
  case 'right':
    anchorClasses.push(classes.linkRight)
    break
  default:
    break
  }

  if (variant) anchorClasses.push(classes[`link--${variant}`])

  if (variant && inverseColor) anchorClasses.push(classes[`link--${variant}-inverse`])

  const renderButtonType = (variant: ButtonVariant, children: ReactNode)=> {
    const buttonClasses = [classes.link]

    if (variant) buttonClasses.push(classes[`link__icon--${variant}`])

    switch (variant) {
    case 'menu-item':
      return <DefaultLink>{children}</DefaultLink>
    case 'primary':
      return <PrimaryButton hover={hover}>{children}</PrimaryButton>
    case 'primary-with-arrow':
      return (
        <>
          <PrimaryButton hover={hover}>{children}</PrimaryButton>
          <RightArrow
            className={classnames(classes['link__icon'], buttonClasses)}
          />
        </>
      )
    case 'secondary':
      return <DefaultLink>{children}</DefaultLink>
    case 'secondary-with-arrow':
      return (
        <>
          <DefaultLink>{children}</DefaultLink>
          <RightArrow
            className={classnames(classes['link__icon'], buttonClasses)}
          />
        </>
      )
    case 'list-style':
      return (
        <>
          <LinkListIcon
            className={classnames(buttonClasses)}
            iconColor={iconColor}
          />
          <DefaultLink>{children}</DefaultLink>
          <RightArrow
            className={classnames(classes['link__icon'], buttonClasses)}
          />
        </>
      )
    default:
      return (
        <DefaultLink>{children}</DefaultLink>
      )
    }
  }

  const isExternalURL = href.startsWith('https://') || href.startsWith('http://') ? true : false

  return (
    <Link
      { ...props }
      href={href.startsWith('http') ? href : (href.charAt(0) === '/' ? href :`/${href}`)}
      passHref
    >
      <a
        className={classnames(anchorClasses)}
        target={linkTarget ? '_blank' : (isExternalURL ? '_blank' : '_self')}
      >
        {renderButtonType(variant, children)}
      </a>
    </Link>
  )
}
