import { PropsWithChildren } from 'react'
import { Grid } from '../../components/Grid/Grid'
import { Logo } from '../../components/Logo'
import { Menu } from '../../components/Menu'
import { MobileMenu } from '../../components/MobileMenu'
import classes from './header.module.scss'

type MenuType = {
  component: string
  label: string
  link: {
    cached_url: string
  }
  _editable: string
  _uid: string
}

const Header: React.FC<PropsWithChildren<{ nav: MenuType[]; inverted?: boolean; project?: string; isHome?: boolean }>> = ({ nav, inverted, project, isHome }) => {
  return (
    <Grid container className={classes.header}>
      <Logo
        className="title"
        href="/"
        title= "Atomix"
        inverse={inverted && project === 'project-details'}
        isHome={isHome}
      />
      <div className={classes.desktop}>
        <Menu data={nav} darkMode={inverted} isHome={isHome} />
      </div>
      <div className={classes.mobile}>
        <MobileMenu data={nav} />
      </div>
    </Grid>
  )
}

export default Header
