import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { CSSProperties, PropsWithChildren, SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { toggleMobileMenu } from '../../store/slices/mobileMenu'
import logoStyles from './Logo.module.scss'

export type LogoProps = {
  style?: CSSProperties
  className?: string
  href?: string
  title?: string
  inverse?: boolean
  isOpen?: boolean
  isHome?: boolean
}

export const Logo: React.FC<PropsWithChildren<LogoProps>> = ({
  style,
  className,
  href,
  title,
  inverse,
  isOpen,
  isHome,
}) => {
  const router = useRouter()
  const currentPath = router.asPath
  const dispatch = useDispatch()
  const handleClick = (event: SyntheticEvent) =>{
    event.preventDefault()

    if(isOpen){
      dispatch(toggleMobileMenu())
    }

    return currentPath === href ? router.reload() : router.push('/')
  }

  return (
    <div>
      <Link href={href || '#'}>
        <button className={logoStyles.logo__button} aria-label={title ?? 'Atomix'} onClick={(e)=> handleClick(e)}>
          <svg
            viewBox="0 0 214.434 39.948"
            className={classNames(className, logoStyles.logo, {
              [logoStyles['logo--animated'] as string]: isHome,
              [logoStyles.inverse as string]: inverse,
            })}
            style={style}
          >
            <g id="Group_2730" data-name="Group 2730" transform="translate(-798.297 -2131.241)">
              <title>{title}</title>
              <path id="Path_5130" className={logoStyles.logo__path} data-name="Path 5130" d="M2977.64,2213.4v-.629s0-.006,0-.01a1,1,0,0,0-1-.989h-5.113a1,1,0,0,0-1,.989s0,.007,0,.01v.629s0,.007,0,.01v30.967s0,.006,0,.01v.629s0,.006,0,.01a1,1,0,0,0,1,.989h5.113a1,1,0,0,0,1-.989s0-.006,0-.01v-.629s0-.006,0-.01v-30.967S2977.64,2213.407,2977.64,2213.4Z" transform="translate(-2021.185 -74.935)" />
              <g id="Group_1846" data-name="Group 1846" transform="translate(894.31 2136.254)">
                <path id="Path_5131" className={logoStyles.logo__path} data-name="Path 5131" d="M2217.6,2203.348c-5.755,0-9.438,2.84-11.249,6.136a10.763,10.763,0,0,0-10.212-6.136,10.934,10.934,0,0,0-9.959,5.432v-3.787a1,1,0,0,0-.983-1h-5.143a1,1,0,0,0-.984,1v32.172a1,1,0,0,0,1,1h5.115a1,1,0,0,0,1-1v-18.821c0-5.431,2.974-8.533,7.563-8.533,4.463,0,7.111,3.1,7.111,8.533v18.821a1,1,0,0,0,1.007,1h5.16a1,1,0,0,0,1.007-1v-18.821c0-5.431,2.912-8.533,7.5-8.533,4.462,0,7.111,3.1,7.111,8.533v18.821a1,1,0,0,0,1.008,1h5.161a1,1,0,0,0,1.008-1v-19.21C2229.819,2207.807,2224.646,2203.346,2217.6,2203.348Z" transform="translate(-2179.073 -2203.336)" />
              </g>
              <path id="Path_4946" className={logoStyles.logo__path} data-name="Path 4946" d="M1612.521,2220.946c0-11.313,8.852-17.454,17.583-17.454s17.582,6.136,17.582,17.454-8.852,17.454-17.582,17.454S1612.521,2232.258,1612.521,2220.946Zm28.055,0c0-6.917-4.979-10.925-10.472-10.925s-10.537,4.008-10.537,10.925,5.042,10.925,10.537,10.925,10.473-4.008,10.473-10.925Z" transform="translate(-757.606 -67.226)" />
              <path id="Path_5132" className={logoStyles.logo__path} data-name="Path 5132" d="M832.464,2201.821h-5.113a1,1,0,0,0-1,1v2.3a18.431,18.431,0,0,0-10.473-3.246c-8.725,0-17.582,6.137-17.582,17.454s8.852,17.454,17.582,17.454a18.427,18.427,0,0,0,10.473-3.246v2.227a1,1,0,0,0,.989,1h5.133a1,1,0,0,0,.989-1V2202.82A1,1,0,0,0,832.464,2201.821Zm-16.581,28.424,0,0c-5.494,0-10.536-4.008-10.536-10.925s5.042-10.925,10.536-10.925,10.473,4.008,10.473,10.925S821.375,2230.246,815.883,2230.246Z" transform="translate(0 -65.672)" />
              <g id="Group_1847" data-name="Group 1847" transform="translate(838.909 2131.241)">
                <path id="Path_5133" className={logoStyles.logo__path} data-name="Path 5133" d="M1389.546,2153.474l.336-.095A1.422,1.422,0,0,0,1389.546,2153.474Z" transform="translate(-1389.045 -2151.84)" />
                <path id="Path_5134" className={logoStyles.logo__path} data-name="Path 5134" d="M1382.343,2164.014v-.184A.706.706,0,0,0,1382.343,2164.014Z" transform="translate(-1382.337 -2161.564)" />
                <path id="Path_5135" className={logoStyles.logo__path} data-name="Path 5135" d="M1394.381,2153.379h0Z" transform="translate(-1393.543 -2151.84)" />
                <path id="Path_5136" className={logoStyles.logo__path} data-name="Path 5136" d="M1396.377,2138.432v-.591a1,1,0,0,0-1-1H1389.6v-4.835a.72.72,0,0,0-.943-.743l-5.4,1.517h0l-.336.095a.621.621,0,0,0-.5.632v29.149c0,6.012,3.622,8.534,8.726,8.534,1.811,0,4.009-.324,5.231-1.358v-4.448a.8.8,0,0,0-.924-.867c-.034,0-.068,0-.1.008a2.225,2.225,0,0,0-.443.1h0l-.06.012a9.124,9.124,0,0,1-1.573.155c-2.65,0-3.683-1.488-3.683-3.427v-18.772l4.316,0h1.473a1,1,0,0,0,1-1v-.608h0v-2.555Z" transform="translate(-1382.413 -2131.241)" />
              </g>
              <g id="Group_1849" data-name="Group 1849" transform="translate(960.2 2136.837)">
                <path id="Path_5138" className={logoStyles.logo__path} data-name="Path 5138" d="M3508.352,2705.52h-.222A1.714,1.714,0,0,0,3508.352,2705.52Z" transform="translate(-3481.602 -2671.184)" />
                <path id="Path_5139" className={logoStyles.logo__path} data-name="Path 5139" d="M3493.307,2211.725h.233A1.7,1.7,0,0,0,3493.307,2211.725Z" transform="translate(-3467.81 -2211.721)" />
                <path id="Path_5140" className={logoStyles.logo__path} data-name="Path 5140" d="M3226.811,2705.52h-.222A1.718,1.718,0,0,0,3226.811,2705.52Z" transform="translate(-3219.637 -2671.184)" />
                <path id="Path_5141" className={logoStyles.logo__path} data-name="Path 5141" d="M3237.8,2211.725h.233A1.7,1.7,0,0,0,3237.8,2211.725Z" transform="translate(-3230.07 -2211.721)" />
                <path id="Path_5142" className={logoStyles.logo__path} data-name="Path 5142" d="M3160.223,2245.216h0l-12.591-16.671,11.508-16.026h0s.42-.739-.554-.742h-6.458a1.176,1.176,0,0,0-.887.4h0l-7.859,10.973-7.9-10.973a1.176,1.176,0,0,0-.887-.4h-6.437c-.975,0-.554.742-.554.742l11.873,15.838-.13.176.01.013-12.618,16.671h0s-.457.748.508.893h6.557a1.233,1.233,0,0,0,.849-.344l8.837-11.986,8.809,11.974a1.22,1.22,0,0,0,.859.355h6.556C3160.681,2245.964,3160.223,2245.216,3160.223,2245.216Z" transform="translate(-3126.631 -2211.772)" />
                <path id="Path_5143" className={logoStyles.logo__path} data-name="Path 5143" d="M3511.329,2705.515h0Z" transform="translate(-3484.579 -2671.178)" />
                <path id="Path_5144" className={logoStyles.logo__path} data-name="Path 5144" d="M3226.52,2705.515h0Z" transform="translate(-3219.574 -2671.178)" />
              </g>
              <g className={classNames(logoStyles.dot, {
                [logoStyles['dot--animated'] as string]: true,
              })} id="Group_1848" data-name="Group 1848" transform="translate(998.089 2156.421)">
                <path id="Path_5137" className={classNames(logoStyles.logo__path, logoStyles['logo__path--blue'])} data-name="Path 5137" d="M3685.915,2500.646c-.234-2.832-1.517-5.75-4.313-6.8a8.373,8.373,0,0,0-6.578.41,6.834,6.834,0,0,0-3.5,6.027,7.777,7.777,0,0,0,1.042,3.643,9.832,9.832,0,0,0,.8,1.182,8.3,8.3,0,0,0,9.182,1.974,7.4,7.4,0,0,0,1.439-1.156A7.671,7.671,0,0,0,3685.915,2500.646Z" transform="translate(-3671.519 -2493.359)" fill="#29bbea"/>
              </g>
            </g>
          </svg>
        </button>
      </Link>
    </div>
  )
}
