import React, { FC } from 'react'
import { ScrollerMotion } from 'scroller-motion'
import { useMediaQuery } from '../../hooks/useMediaQuery'

export type SmoothScrollTypes = {
  children: React.ReactNode
  disabled?: boolean
}

export const SmoothScroll: FC<SmoothScrollTypes> = ({ children, disabled }) => {
  const isDevice = useMediaQuery('(max-width: 1024px')

  return (
    <ScrollerMotion
      disabled={disabled ? disabled : isDevice}
      spring={
        {
          mass: 1.5,
          stiffness: 350,
          damping: 50,
        }
      }
    >
      {children}
    </ScrollerMotion>
  )
}

export default SmoothScroll
