import classnames from 'classnames'
import { motion } from 'framer-motion'
import React, { PropsWithChildren } from 'react'
import { CustomLink } from '../../CustomLink'
import styles from './Menu.module.scss'

type MenuLinkProps = {
  darkMode?: boolean
  isHome?: boolean
  data?: {
    label: string
    link: {
      cached_url: string
    }
  }[]
}

export const DesktopNav: React.FC<PropsWithChildren<MenuLinkProps>> = (props) => {
  const data = props.data
  const darkMode = props.darkMode
  const isHome = props.isHome
  const navStyles = [styles.nav]
  const menuStyles = [styles.nav__menu]

  if (darkMode) navStyles.push(styles['dark'])
  if (isHome) menuStyles.push(styles.isHome)

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.5,
        duration: 0.5,
        staggerChildren: 0.25,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
    },
  }

  const item = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <motion.nav
      className={classnames(navStyles)}
      initial={isHome ? 'hidden' : 'visible'}
      animate="visible"
      variants={variants}
    >
      <motion.ul
        className={classnames(menuStyles)}
        variants={variants}
      >
        {data && data.map(({ label, link }, key: number) => {
          return (
            <motion.li key={key}
              variants={item}
            >
              <CustomLink
                variant={'menu-item'}
                href={link.cached_url || '#'}
              >
                {label}
              </CustomLink>
            </motion.li>
          )
        })}
      </motion.ul>
    </motion.nav>
  )
}
