import Head from 'next/head'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { store } from '../store/store'
import Header from '../slices/Header'
import '../../public/fonts/stylesheet.css'
import './global.scss'
import React, { useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SmoothScroll from '../components/SmoothScroll'
import { AnimatePresence } from 'framer-motion'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

const BlogHeaderWithBanner = dynamic(() => import('../slices/BlogHeaderWithBanner'))
const HeaderMenuAndBannerContainer = dynamic(() => import('../slices/ProjectDetailHeader'))

// This default export is required in a new `pages/_app.js` file.

// @eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppPropsType = any

export default function AtomixApp({ Component, pageProps }: AppProps<AppPropsType>) {
  const Wrapper = pageProps?.doc?.data?.story?.content?.component === 'posts' ? BlogHeaderWithBanner : (pageProps?.doc?.data?.story?.content?.component === 'project-details' ? HeaderMenuAndBannerContainer : React.Fragment)
  const router = useRouter()

  /**
   * Without this when changing pages sometimes FCP sections are missing CSS.
   *
   * @see https://github.com/vercel/next.js/issues/33286
   */
  useEffect(() => {
    const handleRouteChange = () => {
      const styleElements = document.querySelectorAll('head link[data-n-p]')

      for (const styleTag of styleElements) {
        styleTag.removeAttribute('data-n-p')
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChange)
  }, [router])

  return  (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_GTM_CODE && (
          <script dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CODE}');` }}></script>
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="preconnect" href="https://a.storyblok.com" />
        <link rel="dns-prefetch" href="https://a.storyblok.com" />
        {process.env.NEXT_PUBLIC_DISABLE_INDEXING && (
          <meta name="robots" content="noindex,nofollow" />
        )}
      </Head>
      <Provider store={store}>
        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <SmoothScroll disabled={true}>
            <div className={'wrapper'}>
              <Wrapper slice={pageProps?.doc?.data?.story}>
                <Header
                  nav={pageProps?.menus?.[0]?.content?.nav}
                  inverted={pageProps?.doc?.data?.story?.content?.invert_menu}
                  project={pageProps?.doc?.data?.story?.content?.component}
                  isHome={pageProps?.doc?.data?.story?.full_slug === 'home' ? true : false}
                />
              </Wrapper>
              <Component {...pageProps} />
            </div>
          </SmoothScroll>
        </AnimatePresence>
      </Provider>
    </>
  )
}
